@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$theme-colors: (
  primary: $green,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);

$form-label-font-weight: bold;
$form-label-font-style: italic;

$btn-link-color: white;
$input-btn-focus-color: $green;

$table-striped-bg: $green-100;

p p {
  display: inline;
}

.btn-primary:focus {
  background-color: darkgreen;
  box-shadow: 0 0 0 0.2rem #509374ed;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: forestgreen;
  border-color: #0D7D16;
}

.table-hover tbody tr:hover {
  background-color: #1cc88a42;
}

#internal-system-title {
  font-size: 1.5rem;
}

#members-list-title-row {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

#member-data-stack {
  padding-left: 0;
}

#generate-products-btn {
  padding-top: 32px;
}

.aligned-btn {
  padding-top: 32px;
}

.link-button-primary:link {
  color: white;
  text-decoration: none;
}

.link-button-primary:visited {
  color: white;
  text-decoration: none;
}

.height-100 {
  height: 100%;
}

h3 {
  font-size: 1.6em;
}

h1 {
  font-size: xx-large;
}

.bg-gradient-primary {
  background-image: linear-gradient(180deg, #0D7D16 10%, #053100 100%);
}

// Membership credential

.credential {
  font-family: 'Montserrat', sans-serif;
}

.outside {
  display: flex;
  flex-wrap: wrap;
  border-radius: 1.5em;
  border-color: green;
  border-style: solid;
  height: 280px;
  width: 500px;
}

.club-section {
  background-color: seagreen;
  border-top-left-radius: 1.3em;
  border-top-right-radius: 1.3em;
  height: 25%;
}

.club-section-first-line {
  display: flex;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.club-section-second-line {
  display: block;
  margin-top: -2.0em;
  text-align: right;
  width: 71%;
}

.club-font {
  color: white;
  font-size: 22px;
  letter-spacing: 0.075em;
  margin: 0;
  padding-top: 1em;
  width: inherit;
}

.member-number-font {
  color: white;
}

.club-logo {
  margin-left: 1.5em;
}

.main-section {
  display: flex;
  height: 62%;
  margin-top: 0.5em;
  width: 100%;
}

.picture-section {
  display: inline-block;
  //margin-top: -1.5em;
  padding-left: 1.5em;
  margin-right: -1em;
  width: 30%;
}

.data-section {
  display: inline-block;
  width: 45%;
}

.qr-section {
  text-align: center;
}

.data-section-below-picture {
  display: block;
  margin-top: -2.6em;
  margin-left: 1.5em;
  width: 35%;
}

.data-field-member-picture {
  margin-top: 0.85em;
}

.data-field-value {
  display: block;
}

.data-field {
  width: 100%;
}

.label {
  color: darkgreen;
  margin-bottom: 0em;
  font-size: 12px;
}

.value {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
}

.club-section-bottom {
  background-color: seagreen;
  border-bottom-left-radius: 1.3em;
  border-bottom-right-radius: 1.3em;
  height: 10%;
  width: 100%;
}

.club-font-slogan {
  color: white;
  align-content: center;
  font-family: "Brush Script MT";
  font-size: 22px;
  letter-spacing: 0.05em;
  margin: 0;
  width: inherit;
}

.club-section-bottom-line {
  display: flex;
  justify-content: center;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#qr {
  max-height: 120px;
  max-width: 120px;
  vertical-align: middle;
}

#credential-member-picture {
  margin-top: -1.5em;
}

@import '~bootstrap/scss/bootstrap';
